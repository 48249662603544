<template>
  <header class="header pt-4 pb-4">
    <div class="container">
      <div class="row">
        <div class="col-8 col-lg-4">
          <div class="header__logo">
            <img
              src="@/assets/nexi-logo.svg"
              class="img-fluid"
              alt="ISO Portal | Nexi"
              @click="routeTo('/')"
            />
          </div>
        </div>
        <div class="col-4 col-lg-8">
          <div class="header__btn">
            <button
              v-if="$system.user.isAuthenticated"
              class="btn btn-login"
              @click="logout"
            >
              Logout
            </button>
            <button v-else @click="loginTo()" class="btn btn-login">
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import './Header.scss'

export default {
  name: 'Header',
  data() {
    return {}
  },
  methods: {
    logout() {
      this.$system.logout()
      if (this.$router.currentRoute.path !== '/') {
        this.$router.push('/')
      }
    },
    routeTo(link) {
      if (this.$router.currentRoute.path !== '/') {
        this.$router.push(link)
      }
    },
    loginTo() {
      if (this.$router.currentRoute.path !== '/login') {
        this.$router.push('/login')
      }
    },
  },
}
</script>
