<template>
  <div class="footer mt-5">
    <div class="container">
      <div class="row text-center">
        <div class="col-12 p-4 border-b">
          <img
            class="img-fluid pl-5 pr-5"
            src="@/assets/nexi-logo.svg"
            alt="Nexi"
          />
        </div>
        <div class="col-12 p-4">
          © Copyright {{ date }}
          <a href="https://gonexi.com" target="_black">
            Nexi.
          </a>
          All Rights Reserved
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './Footer.scss'

export default {
  name: 'Footer',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  },
}
</script>
